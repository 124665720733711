@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@100;200;300;400;500;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', "IBM Plex Sans Arabic",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ibm-plex sans arabic-thin {
  font-family: "IBM Plex Sans Arabic", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.ibm-plex sans arabic-extralight {
  font-family: "IBM Plex Sans Arabic", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.ibm-plex sans arabic-light {
  font-family: "IBM Plex Sans Arabic", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.ibm-plex sans arabic-regular {
  font-family: "IBM Plex Sans Arabic", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.ibm-plex sans arabic-medium {
  font-family: "IBM Plex Sans Arabic", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.ibm-plex sans arabic-semibold {
  font-family: "IBM Plex Sans Arabic", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.ibm-plex sans arabic-bold {
  font-family: "IBM Plex Sans Arabic", sans-serif;
  font-weight: 700;
  font-style: normal;
}

input::placeholder {
  color: #a5a5a5 !important;
}